export default [
  {
    path: '/commerciaux/liste',
    name: 'commerciaux-liste',
    component: () => import('@/views/afridia/commerciaux/liste/MainVue.vue'),
    meta: {
      pageTitle: 'Commerciaux - Liste',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
    },
  },
  {
    path: '/commerciaux/details/:id',
    name: 'commerciaux-details',
    component: () => import('@/views/afridia/commerciaux/details/MainVue.vue'),
    meta: {
      pageTitle: 'Commerciaux - Details',
      breadcrumb: [
        {
          text: 'Infos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/commerciaux/visite-pdv/:commercial_id/:tournee_id',
    name: 'commerciaux-visite-pdv',
    component: () => import('@/views/afridia/commerciaux/visite-pdv/MainVue.vue'),
    meta: {
      pageTitle: 'Commerciaux - Visite PDV',
      breadcrumb: [
        {
          text: 'Visite',
          active: true,
        },
      ],
    },
  },
  {
    path: '/commerciaux/visite-distributeur/:id',
    name: 'commerciaux-visite-distributeur',
    component: () => import('@/views/afridia/commerciaux/visite-distributeur/MainVue.vue'),
    meta: {
      pageTitle: 'Commerciaux - Visite Distributeur',
      breadcrumb: [
        {
          text: 'Visite',
          active: true,
        },
      ],
    },
  },
  {
    path: '/commerciaux/objectifs',
    name: 'commerciaux-objectifs-distributeurs',
    component: () => import('@/views/afridia/commerciaux/objectifs/MainVue.vue'),
    meta: {
      pageTitle: 'Commerciaux - Objectifs',
      breadcrumb: [
        {
          text: 'Objectifs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/commerciaux/statistiques',
    name: 'commerciaux-statistiques',
    component: () => import('@/views/afridia/commerciaux/statistiques/MainVue.vue'),
    meta: {
      pageTitle: 'Commerciaux - Statistiques',
      breadcrumb: [
        {
          text: 'Statistiques',
          active: true,
        },
      ],
    },
  },
]
